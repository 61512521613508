@import url(~rrweb/dist/rrweb.min.css);

#next-assist-viewer {
  position: relative;
}

#op-grass-pane {
  z-index: 51000;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

#op-drawing-grass-pane {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  border-width: 2px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
}

/* For Document Push */
.viewer-toolbar > ul > .viewer-large::before {
  margin: 10px !important;
}

.viewer-toolbar > ul > .viewer-large {
  height: 55px !important;
  width: 55px !important;
}

.viewer-zoom-in::before,
.viewer-zoom-out::before,
.viewer-one-to-one::before,
.viewer-reset::before,
.viewer-prev::before,
.viewer-play::before,
.viewer-next::before,
.viewer-rotate-left::before,
.viewer-rotate-right::before,
.viewer-flip-horizontal::before,
.viewer-flip-vertical::before,
.viewer-fullscreen::before,
.viewer-fullscreen-exit::before,
.viewer-close::before {
  background-size: 500px !important;
  height: 40px !important;
  width: 40px !important;
}

.viewer-zoom-in::before {
  background-position: -1px -2px !important;
}

.viewer-zoom-out::before {
  background-position: -36px -2px !important;
}

.viewer-prev::before {
  background-position: -144px -2px !important;
}

.viewer-next::before {
  background-position: -214px -2px !important;
}

@media screen and (max-width: 740px) {
  .viewer-toolbar {
    margin-bottom: 65px !important;
  }
}
