body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#alert-dialog-title {
  background-color: rgb(128, 168, 204);
  color: #fff;
}
#alert-dialog-title h2 {
  font-weight: bold;
}
#alert-dialog-title + div {
  margin-top: 15px;
}
#alert-dialog-title + div + div button:first-child {
  background-color: rgb(224, 224, 224);
}
.ra-confirm {
  background-color: #3364a0 !important;
  color: #fff !important;
}
.ra-confirm,
#alert-dialog-title + div + div button:first-child {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 10px 20px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-size: 1rem !important;
}
#alert-dialog-title + div + div button:first-child svg,
.ra-confirm svg {
  display: none;
}
